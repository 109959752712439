import { Sortable } from 'sortablejs';

function setupSorting() {
    const elements = document.querySelectorAll('.sortable');

    for (const element of elements) {
        Sortable.create(element,
            {
                sort: true,
                onEnd: function(evt) {
                    const oldIndex = evt.oldDraggableIndex;
                    const newIndex = evt.newDraggableIndex;

                    if (oldIndex !== newIndex) {
                        // Re-number all the children.
                        let i = 0;

                        for (const child of element.children) {
                            child.querySelector('input[type=hidden]').setAttribute('value', String(i));
                            i++;
                        }
                    }
                }
            }
        );
    }
}

export { setupSorting };
