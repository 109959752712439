function makeEl(html) {
    const template = document.createElement('template');

    template.innerHTML = html.trim();

    return template.content.firstChild;
}

function clearEl(...elements) {
    [].concat(...elements).forEach(el => { while (el.firstChild) el.removeChild(el.firstChild); });
}

function escape(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export { clearEl, makeEl, escape };