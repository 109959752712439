import PhotoSwipeLightbox from './vendor/photoswipe-lightbox.esm';
import PhotoSwipe from './vendor/photoswipe.esm';

function setupPhotoGallery() {
    if (document.querySelector('#gallery')) {
        new PhotoSwipeLightbox({
            gallery: '#gallery',
            children: 'a.gallery__attachment',
            pswpModule: PhotoSwipe
        }).init();
    }
}

export { setupPhotoGallery };