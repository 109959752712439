/* Handles turning regular file uploads on the page into a fancy multiple file upload using AJAX. */
import { makeEl } from "./dom";

function bytesToMegabytes(value) {
    return value / 1024.0 / 1024.0;
}

/** Simple class that represents our little progress bar / info field for each uploaded file. */
class ProgressWidget {
    constructor(element, fileName) {
        this.progress = element.querySelector('progress');
        this.infoField = element.querySelector('.js-upload-info');
        this.fileName = fileName;
    }

    /** Set the progress amount to a given amount, updating the progress bar. */
    update(progress, text) {
        this.progress.setAttribute('value', String(progress));
        this.infoField.innerText = `${this.fileName} Uploading: ${progress}% (${text})...`;
    }

    /** Finish the upload successfully, hiding the progress bar and updating the status text. */
    finish() {
        this.progress.classList.add('is-hidden');
        this.infoField.innerText = `${this.fileName} Upload complete!`;
    }

    /** Finish the upload with an error, hiding the progress bar and updating the status text to the given error. */
    error(message) {
        this.progress.classList.add('is-hidden');
        this.infoField.innerText = this.fileName + ' ' + message;
    }
}

class AjaxFileUploadWidget {
    constructor(rootElem) {
        this.duplicated = false;
        this.rootElem = rootElem;
        this.fileField = rootElem.querySelector('input[type=file]');
        this.fileLabel = rootElem.querySelector('.file-label');
        this.nameField = rootElem.querySelector('.file-name');
        this.submitButton = rootElem.querySelector('input[type=submit]');
    }

    attach() {
        // Update file name displayed to the user
        this.fileField.addEventListener('change', e => {
            this.nameField.innerText =
                Array.prototype.map.call(this.fileField.files, (f => f.name)).join(', '); // TODO: This may not work on all browsers?
        });

        this.submitButton.addEventListener('click', e => {
            e.preventDefault();
            this.onSubmit();
        });
    }

    doUpload(file, url) {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
        const progressWidget = this.makeProgressWidget(file.name);

        formData.append('attachment', file);
        xhr.upload.addEventListener('progress', e => {
            if (e.lengthComputable) {
                const percent = Math.round((e.loaded / e.total) * 100);

                progressWidget.update(percent, `${bytesToMegabytes(e.loaded).toFixed(2)}/${bytesToMegabytes(e.total).toFixed(2)} MB`);
            }
        });

        xhr.addEventListener('load', _ => {
            if (xhr.status < 200 || xhr.status > 299) {
                const response = JSON.parse(xhr.response);
                if (response.errors) {
                    progressWidget.error(`Upload error occurred (${response.errors.join(', ')},) please try again!`);
                } else {
                    progressWidget.error(
                        `Upload error occurred (${xhr.status} ${xhr.statusText},) please try again!`
                    );
                }
            } else {
                progressWidget.finish();
            }
        });

        xhr.addEventListener('error', _ => {
            progressWidget.error('Upload error occurred, please try again!')
        });

        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
        xhr.send(formData);
    }

    onSubmit() {
        if (this.fileField.files.length === 0 || !this.fileField.files[0]) {
            return;
        }

        for (let i = 0; i < this.fileField.files.length; i++) {
            this.doUpload(this.fileField.files[i], this.rootElem.getAttribute('action'));
        }

        this.fileField.files.length = 0;
        this.nameField.innerText = 'Select more files...';
    }

    makeProgressWidget(fileName) {
        const element = makeEl(`
            <div>
                <span class="js-upload-info"></span>
                <progress class="progress is-success" value="0" max="100"></progress>
            </div>
        `);

        return new ProgressWidget(
            this.rootElem.appendChild(element),
            fileName
        );
    }
}

function setupFileUpload(rootElem) {
    new AjaxFileUploadWidget(rootElem)
        .attach();
}

function setupFileUploads() {
    const uploadContainers = document.querySelectorAll('.js-file-upload');

    for (const uploadContainer of uploadContainers) {
        setupFileUpload(uploadContainer);
    }
}

export { setupFileUploads };
