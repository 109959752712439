function setupAdminCollapse() {
    const main = document.querySelector('main');
    const adminToolsBox = document.querySelector('#admin-tools');

    if (!adminToolsBox) {
        return;
    }

    const collapseButton = adminToolsBox.querySelector('#admin-tools-collapse');

    collapseButton.addEventListener('click', (e) => {
        main.classList.remove('main--admin');
        main.classList.add('main--user');

        adminToolsBox.parentNode.removeChild(adminToolsBox);
    });
}

export { setupAdminCollapse };
